import { JsonPipe, NgSwitch } from '@angular/common';
import {
    Component,
    EventEmitter,
    HostListener,
    inject,
    Input,
    Output,
} from '@angular/core';
import { WindowsSizeService } from '@app/services/windows-size/windows-size.service';
import { NavigationService } from '@app/services/navigation/navigation.service';
import { LocalStorageService } from '@app/services/local-storage/local-storage.service';
import { AutocompletePipe } from '@app/pipes/autocomplete/autocomplete.pipe';
import { CategoryParseView } from '@app/models/category/category-parse-view.model';

@Component({
    selector: 'app-auto-completed-search',
    standalone: true,
    imports: [NgSwitch, JsonPipe],
    templateUrl: './auto-completed-search.component.html',
    styleUrl: './auto-completed-search.component.sass',
})
export class AutoCompletedSearchComponent {
    public windowsSizeService: WindowsSizeService = inject(WindowsSizeService);
    private navigationService: NavigationService = inject(NavigationService);

    @Input() set dataSourceFilter(res: CategoryParseView[]) {
        this.dataSource = res;
    }

    @Output() result: EventEmitter<CategoryParseView> =
        new EventEmitter<CategoryParseView>();
    @Input() backgroundColor: string = '#bdbdbd';
    @Input() color: string = '#FFFF';

    autocompletePipe: AutocompletePipe;
    dataSource: CategoryParseView[] = [];

    constructor() {
        this.autocompletePipe = new AutocompletePipe(this.windowsSizeService);
    }

    selectItem(res: CategoryParseView): void {
        this.result.emit(res);
    }

    navigatePage(option: CategoryParseView): void {
        switch (option.type) {
            case 'profile':
                this.navigationService.navigatePage(`profile/${option._id}`);
                break;
            case 'publication':
                this.navigationService.navigatePage(
                    `publicacion/${option._id}`
                );
                break;
            case 'category':
                this.navigationService.navigatePage(`/categoria/${option._id}`);
                break;
            case 'subcategory':
                this.navigationService.navigatePage(
                    `/categoria/${option.parent._id}/subcategoria/${option._id}`
                );
                break;
        }
    }
}
